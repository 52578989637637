import Vue from 'vue'
import Vuex from 'vuex'
import config from '../config/app.config'
Vue.use(Vuex)

let lang, myAcount, isLoading

const projectName = config.projectName

try {
  lang = localStorage.getItem(`${projectName}Lang`)
} catch {
  localStorage.setItem(`${projectName}Lang`, 'EN')
  lang = 'EN'
}

try {
  myAcount = ''
} catch {
  myAcount = null
}

try {
  isLoading = false
} catch {
  isLoading = false
}

var signData = {
  message: '',
  signature: '',
  address: ''
}
const tempSignData = localStorage.getItem(`${projectName}Sign`)
if (tempSignData) {
  signData = JSON.parse(tempSignData)
}

// console.log(config)

export default {
  lang,
  myAcount, // 钱包账户地址
  isLoading,
  signData // 签名数据
}
