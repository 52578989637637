const ZH = {
  daoList: [
    {
      url: '/stake',
      text: '質押',
      icon: 'icon-shequ'
    },
    {
      url: '/stakeRecord',
      text: '質押記錄',
      icon: 'icon-zhiyajiezhiya'
    },
    {
      url: '/donate',
      text: '捐赠',
      icon: 'icon-aixinjuanzeng'
    },
    {
      url: '/community',
      text: '社區',
      icon: 'icon-jilu'
    }
  ]
}
export default ZH
