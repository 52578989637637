const ZH = {
  community: '社區',
  myLink: '我的邀請鏈接',
  copy: '複製',
  inviter: '我的邀請人',
  enterLink: '填寫邀請人地址',
  teamInfo: '團隊信息',
  teamNum: '團隊總人數',
  totalLp: '直推總LP',
  teamTotalLp: '團隊總LP',
  inviteNotice: '請確認地址是否正確，此操作撤銷人可撤銷',
  confirm: '確認',
  commingSoon: '敬請期待',
  numError: '數量錯誤',
  getLp: '一鍵獲取LP',
  balance: '餘額',
  getMusd: '獲取MUSD',
  inputTokenAmount: '輸入{token}的數量',
  remove: '移除',
  removeLp: '移除LP',
  imputRemoveAmount: '輸入要取回的{token}數量'
}
export default ZH
