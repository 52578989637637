<template>
  <div class="slide-bar">
    <VanPopup get-container="#app" v-model="show" position="left"
      :style="{ height: '100%', width: '275px', background: 'transparent' }" @click-overlay="close">
      <div class="bar-wrapper">
        <!-- <div class="logo-img">
           <img src="@/assets/img/logo.png" />
        </div>
        <div class="split-line"></div> -->
        <div class="menu-list">
          <div class="menu-item"    v-for="(item, index) in list" :key="index"
           @click="handleToMenu(item,index)">
            <div v-if="item.id==9 ||item.id==8 || item.id==6 || item.id==3 || item.id === 4" class="item-box" :class="{ active: showDropdown == item.id}">
              <div class="menu-box" :class="{ active: showDropdown == item.id}">
                <span>{{ item.text }}</span>
              </div>
              <div class="text">
                NEW
              </div>
            </div>
            <div v-else-if="item.id==2">
              <div class="item-box" @click="handleshow" :class="{ active: showDropdown == item.id}">
                <div class="menu-box" :class="{ active: showDropdown == item.id}" >
                  <span>{{ item.text }}</span>
                </div>
                <div>
                  <img class="arrow" src="../assets/home/down.png" v-if="!arrowshow" />
                  <img class="arrow" src="../assets/home/up.png" v-else />
                </div>
              </div>
              <div v-if="arrowshow">
                <div class="show-box" v-for="(child, childIndex) in item.child" :key="child.id"
                @click="handleToMenu(child, childIndex)">{{ child.text }}</div>
              </div>
            </div>
            <div v-else class="item-box">
              <div class="menu-box" :class="{ active: showDropdown == item.id}">
                <!-- <img class="iconfont-img" :src="icons[item.id]arrow"> -->
                <span>{{ item.text }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VanPopup>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'SlideBar',
  props: ['showDropdown'],
  data () {
    return {
      // defaultImg: require('@/assets/head_portrait1.png'),
      defaultImg: '',
      show: true,
      connectShow: false,
      message: '',
      signature: '',
      arrowshow: false,
      icons: [
        // require('../assets/icon/home.svg'), require('../assets/icon/hourse.svg'),
        // require('../assets/icon/person.svg'), require('../assets/icon/page.svg')
      ]
    }
  },
  computed: {
    ...mapState(['lang', 'myAccount', 'signData', 'userInfo']),
    common () {
      return this.$t('login')
    },
    list () {
      const menulist = []
      this.$t('sideBar').forEach(item => {
        menulist.push(item)
      })
      return menulist
    }
  },
  methods: {
    handleshow () {
      this.arrowshow = !this.arrowshow
    },
    ...mapActions(['changeAccount', 'changeLang', 'changeSignData']),
    close () {
      this.$emit('close')
    },
    handleToMenu (item, index) {
      if (!item.url) return
      this.$emit('close', item.id)
      if (item.urlType) {
        window.open(item.url, '_blank')
        return
      }
      this.$gbUtils.handleToPage(item.url)
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.bar-wrapper {
  width: 100%;
  height: calc(100vh - 50px);
  background: #18191A;
  padding: 0px 24px;
  overflow: scroll;
  margin-top: 50px;
  .biaoti {
    font-size: 17px;
    font-weight: 800;
    color: #FFFFFF;
  }

  .split-line {
    border: 1px solid #363636;
    margin: 28px auto;
  }

  .logo-img {
    width: 100%;
    margin-top: 17px;

    img {
      width: auto;
      height: 40px;
    }
  }
  .menu-list {
    margin-bottom: 30px;

    .menu-item {
      padding: 16px 0px;
      font-size: 15px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      &:last-child {
        border-bottom: none;
      }

      .menu-box {
        display: flex;
        font-size: 16px;
        font-weight: 500;
        color: #F5F5F5;
        &.active{
        font-size: 16px;
        font-weight: 500;
        color: #9B9CFF;
      }
        .iconfont {
          margin-right: 22px;
          font-size: 20px;
        }

        .iconfont-img {
          margin-right: 22px;
          width: 20px;
          color: #FFFFFF;
        }
      }

      .item-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .show-box {
        font-size: 14px;
        font-weight: 400;
        color: rgba(245, 245, 245, 0.8);
        padding: 14px 24px 0;
      }
    }
  }
}
.text{
  padding: 5px 8px;
  background: linear-gradient(135deg, #FFAF66 0%, #FF6666 100%);
  box-shadow: 0px 0px 10px 0px rgba(255,104,104,0.2);
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
  font-size: 12px;
  font-weight: 500;
  color: #F5F5F5;
}
</style>
