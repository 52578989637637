import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/dao',
    name: 'DAO',
    meta: {
      isBack: true
    },
    component: () => import('../views/home/DAO.vue')
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      isBack: true
    },
    component: () => import('../views/homeNew/Home.vue')
  },
  {
    path: '/contribution',
    name: 'Contribution',
    meta: {
      isBack: true
    },
    component: () => import('../views/contribution/Contribution.vue')
  },
  {
    path: '/invite',
    name: 'Invite',
    meta: {
      isBack: true
    },
    component: () => import('../views/Invite.vue')
  },
  {
    path: '/advertisement',
    name: 'Advertisement',
    meta: {
      isBack: true
    },
    component: () => import('../views/Advertisement.vue')
  },
  {
    path: '/donateDividends',
    name: 'DonateDividends',
    meta: {
      isBack: true
    },
    component: () => import('../views/DonateDividends.vue')
  },
  {
    path: '/community',
    name: 'Community',
    meta: {
      isBack: true
    },
    component: () => import('../views/Community.vue')
  },
  {
    path: '/activity',
    name: 'Activity',
    meta: {
      isBack: true
    },
    component: () => import('../views/activity/Activity.vue')
  },
  {
    path: '/donate',
    name: 'Donate',
    meta: {
      isBack: true
    },
    component: () => import('../views/activity/Donate.vue')
  },
  // {
  //   path: '/upgrade',
  //   name: 'Upgrade',
  //   meta: {
  //     isBack: true
  //   },
  //   component: () => import('../views/activity/Upgrade.vue')
  // }
  {
    path: '/maiAirdrop',
    name: 'MaiAirDrop',
    meta: {
      isBack: true
    },
    component: () => import('../views/MaiAirDrop/MaiAirDrop.vue')
  },
  {
    path: '/otcTransaction',
    name: 'OtcTransaction',
    meta: {
      isBack: true
    },
    component: () => import('../views/otcTransaction/OtcTransaction.vue')
  },
  {
    path: '/myHangOrder',
    name: 'MyHangOrder',
    meta: {},
    component: () => import('../views/otcTransaction/MyHangOrder.vue')
  },
  {
    path: '/otcAgentTransaction',
    name: 'OtcAgentTransaction',
    meta: {
      isBack: true
    },
    component: () => import('../views/otcTransactionAgent/OtcTransaction.vue')
  },
  {
    path: '/otcAgentHangOrder',
    name: 'OtcAgentHangOrder',
    meta: {},
    component: () => import('../views/otcTransactionAgent/MyHangOrder.vue')
  },
  {
    path: '/otcTransactionV1',
    name: 'OtcTransactionV1',
    meta: {
      isBack: true
    },
    component: () => import('../views/otcTransactionOld/OtcTransaction.vue')
  },
  {
    path: '/myHangOrderV1',
    name: 'MyHangOrder',
    meta: {},
    component: () => import('../views/otcTransactionOld/MyHangOrder.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
