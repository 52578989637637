const EN = {
  daoList: [
    {
      url: '/stake',
      text: 'Staking',
      icon: 'icon-shequ'
    },
    {
      url: '/stakeRecord',
      text: 'Records',
      icon: 'icon-zhiyajiezhiya'
    },
    {
      url: '/donate',
      text: 'Donate',
      icon: 'icon-aixinjuanzeng'
    },
    {
      url: '/community',
      text: 'Community',
      icon: 'icon-jilu'
    }
  ]
}
export default EN
