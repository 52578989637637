export default {
  pancakeRouter: '0x10ED43C718714eb63d5aA57B78B54704E256024E', // pancake router 合约地址
  factoryAddress: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73', // pancake factory
  lightingAddress: '0x0a29702D828C3bd9bA20C8d0cD46Dfb853422E98', // 点灯合约地址
  donationAddress: '0x8DDeaD5dA29A08E35110eE0c216A85cBE2C65884', // 捐赠地址
  crowdfunding: '0x4817ff75934A7a552bECA331D4f5a6A3d9aB332f', // 众筹地址 metacrowd
  upgradeAddress: '0x7f4F982b42092D065d876F0082c2653C731546D6', // 捐赠直升V4地址
  maiExchangeAddress: '0x0663C4b19D139b9582539f6053a9C69a2bCEBC9f', // mai赎回
  maiDistribute: '0x73BE415AF826c08ED102fbaebB222758eEb918e6', // mai分发合约
  lpBusiness: '0x7D27511Ffe098De8df0a3784Ae4b405065CD28Fa', // 业务合约 一键兑换lp
  maiAirDrop: '0x6a4746E7cA0f518e3Cb09C0d7aCfbFdd409C1f99', // mai空投合约
  donateProcessor: '0x637eAA90bEc6aB781e7C704584B020686e0803de', // mcoinDonate
  claimAirDrop1: '0x05d5e921bee29c0357E3355596EfF9EC069E9Ee0', // 矩阵空投领取
  claimAirDrop2: '0xC1Bb7696D0e3e5f4b7CDb1f766813d8568006Fa9',
  claimAirDropPool: '0x61d3B4633cC0e85A624F116436306d1BE045f30E', // 矩阵空投分配池
  otc: '0x89ffE796c387F41354e97aF91f563798Db847aA3', // otc交易
  otcV1: '0x91c5e421d53842A9790931dF3c6C9E5551Ca8a77',
  otcAgent: '0xd616b70b18DeC2bDe5666cbE2f055cA8B959Da48', // otc代理合约
  zap: '0xD4c4a7C55c9f7B3c48bafb6E8643Ba79F42418dF' // zap.流动性
}
