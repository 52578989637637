const ZH = {
  ExistingAirdrop: '現存矩陣點位空投',
  TotalNumberOfOpen: '總開啟矩陣點位數',
  Receiveairdrop: '可領取空投',
  Estimated: '待估',
  Addpoint: '新增矩陣點位空投',
  Newpoints: '新增矩陣點位數',
  rule: '規則說明',
  ruleContent1: '<p>1.最大可提出LP數量大於等於100的總開啟矩陣點位數才能進行空投</p>' +
  '<p>2.矩陣點位數快照時間為2022.10.29 21:00</p>',
  ruleContent2: '<p>1.首先要激活新增矩陣點位空投，才能參與後續新增元點空投</p>' +
  '<p>2.用戶每新增一個矩陣點位，都能領取一次空投獎勵</p>',
  ruleContent3: '<p>2023年3月11日0:00後有新增點燈的用戶，可在此領取MAI空投。</p>',
  ActivateAirdrop: '激活空投',
  ReceiveAirdrop: '領取空投',
  Notopened: '暫未開啟',
  maiPool: 'MAI總分配池',
  estimatedToday: '預計今日分配',
  curLightReward: '當前點燈獎勵',
  once: '次'
}
export default ZH
