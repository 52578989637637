const ZH = {
  invitations: '邀請人數不夠',
  Develop: '為DeFi的發展生態',
  Provide: '提供智能合約',
  based: '基於智能合約的',
  platform: '去中心化網絡平臺',
  defi: '連接社區和DeFi',
  let: '讓',
  tui: 'DeFi推廣',
  Simpler: '更簡單',
  join: '加入',
  incentives: '活動回饋',
  easy: '一切迎刃而解，變得簡單',
  points: '所選矩陣點位質押',
  maximum: '預估最高基線',
  matrixs: '矩陣點位數量',
  point: '開啟矩陣點位',
  invitation: '排行榜',
  funds: '發射池總量',
  createTime: '創建時間',
  donation: '捐贈',
  createAccount: '創建賬戶',
  bindRecommender: '綁定推薦人',
  pointStone: '點石成金',
  pointStoneTips: '點石成金需要花費',
  registerProject: '登記您的DeFi項目',
  intoGold: '點石成金',
  placeholderNumber: '請輸入需要開啟的矩陣點位',
  balance: '余額',
  get: '獲取',
  pay: '支付',
  estimatedCost: '預計成本',
  estimatedTotalRevenue: '預計總收益',
  inviteFriends: '邀請好友',
  supply: '拐點供應',
  donateDividends: '捐贈',
  rewardDetails: '詳情',
  seePointAward: '累計驚喜點位',
  dividend: '累計額外點位',
  yuanTotal: '最大可提出LP',
  reviewed: '已提交，未審理',
  goldStone: '矩陣點位數',
  extinguished: '總關閉矩陣點位數',
  activation: '總開啟矩陣點位數',
  cumulativeWithdrawal: '累計提出',
  bonusBalance: '剩餘總分點LP',
  withdraw: '提出',
  withdrawTips: '輸入數量',
  leaderboard: '排行榜',
  contributionTotal: '貢獻排行榜',
  giveOutRewards: '發放',
  contribution: '貢獻值',
  expectedReward: '預計數量',
  accumulatedRewards: '累計數量',
  partner: '合作夥伴',
  numbering: '編號',
  numberOfPeople: '地址',
  contractAddress: '合約地址',
  yuanNumber: '元數量',
  amountOfFunds: '資金量',
  creationTime: '創建時間',
  withdrawMaxTips: '最大可提出',
  addressTips: '請輸入推薦人地址',
  confirm: '確定',
  supplyLP: '供應',
  explain: '規則說明',
  explainFirst: '每一個元激活需要支付',
  explainSecond: '賬號有900個激活的元時可以無限激活元，不足900個激活元時不能激活',
  explainThree: '激活元需要創建賬號成功後才能激活',
  matrix: '開啟您的DeFi項目',
  historicalRecords: '歷史記錄',
  time: '2022-05-14 22:30:00',
  shuoming: '身份說明',
  tipone: '創建賬戶成功後，默認為v0',
  tiptwo: '邀請6位V0成為V1，可獲得10',
  tipthree: '邀請4位V1成為V2，可獲得20',
  tipFour: '邀請2位V2成為V3，可獲得30，每日可開啟10盞',
  upgrade: '升級',
  tipDialog: '創建賬號時，需要激活一個',
  fail: '評估Gas出錯，請重試',
  MetaCrowdfunding: '元起通證競拍系統',
  MetaCrowdfunding1: '元一眾籌(公測版)',
  MetaCrowdfunding2: '元一眾籌 V2.0(通道一 加速)',
  MetaCrowdfunding3: '元一眾籌 V2.0(通道一)',
  MetaBillPool: 'Web3.0人才庫元點',
  workList: '我的工單',
  createTime1: '提交時間',
  updateTime: '處理時間',
  status: '狀態',
  remark: '備註',
  noStatus: '未處理',
  haveStatus: '已處理',
  noData: '暂无数据',
  waitTips: '若查詢時間較長,請十分鐘後再試',
  notNeed: '無需提交',
  youNeedPay: '需支付',
  Get: '一鍵獲取',
  joinmeta: '加入元點人才矩陣',
  getmulp: '一鍵獲取LP',
  pleaseInputLpAmount: '請輸入獲取LP的數量',
  getlpTips: '手動從Pancakeswap兌換MEB-USDT LP',
  maiAirdrop: '人才庫元點空投',
  maiOtc: 'MAI場外OTC交易'
}
export default ZH
