import Vue from 'vue'
import { Toast, Button, Field, Loading, Popup, Swipe, SwipeItem, Icon, Empty, Checkbox, CheckboxGroup } from 'vant'
Vue.use(Toast)
Vue.use(Button)
Vue.use(Field)
Vue.use(Loading)
Vue.use(Popup)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Icon)
Vue.use(Empty)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
