import { setUrlPath } from './index'
import router from '../router'

const handleToPage = (url) => {
  if (url) {
    // 跳转
    router.push(url)
  } else {
    router.replace('/')
  }
}
const handleBack = () => {
  router.go(-1)
}

// 钱包地址格式化
const formatAddress = (value) => {
  if (!value) return 0
  const startAddress = value.slice(0, 5)
  const endAddress = value.slice(-5)
  const address = startAddress + '...' + endAddress
  return address
}

// 货币八位小数
const formatEightBalance = (value) => {
  value = Number(value.toString().match(/^\d+(?:\.\d{0,8})?/))
  return value
}

// 货币四位小数
const formatBalance = (value) => {
  value = Number(value.toString().match(/^\d+(?:\.\d{0,4})?/))
  return value
}

// 货币两位小数
const formatTwoBalance = (value) => {
  value = Number(value.toString().match(/^\d+(?:\.\d{0,2})?/))
  return value
}

// 都转为小写
const toUpperCase = (value) => {
  return value.toUpperCase()
}

// 都转为小写
const toLowerCase = (value) => {
  return value.toLowerCase()
}
const gbUtils = {
  setUrlPath,
  handleToPage,
  handleBack,
  formatBalance,
  formatTwoBalance,
  formatEightBalance,
  formatAddress,
  toUpperCase,
  toLowerCase
}

export default gbUtils
