import Web3 from 'web3'
import config from '@/config/app.config.js' // 绑定 provider
import store from '@/store'
import tokenAbi from '../abi/token.json'

const provider = config.provider
const ethereum = window.ethereum

let web3
try {
  web3 = web3 = new Web3(window.ethereum)
} catch {
  web3 = new Web3(new Web3.providers.HttpProvider(provider))
}
const currentAccount = store.state.myAcount // 当前钱包账户

// 统一错误返回
const failResult = (message) => ({ success: false, message })
// 统一成功返回
const successResult = (result) => ({ success: true, result })

/**
 * 获取当前rpc I
 * @returns chainId
 */
const getChainId = async () => {
  try {
    const chainId = await web3.eth.net.getId()
    return chainId
  } catch {
    return null
  }
}
/*
 * 获取区块信息
 * @returns block
 */
const getBlockNumber = async () => {
  try {
    const block = await web3.eth.getBlockNumber()
    return block
  } catch (error) {
    return 0
  }
}
/*
 * 获取区块信息
 * @returns block
 */
const getBlock = async (number) => {
  try {
    const block = await web3.eth.getBlock(number)
    return block
  } catch (error) {
    return 0
  }
}
/**
 * 签名数据
 * @account 需要签名的账户
 * @data 需要签名的数据
 * @privatekey 私钥
 * @return 签名后的数据
 */
const signData = async (account, data) => {
  if (!account) await connectWallet()
  if (!data) return failResult('Signature data cannot be empty')
  try {
    const signedData = await web3.eth.personal.sign(data, account)
    return successResult(signedData)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 计算给定消息的哈希
 * @message 要进行哈希计算的消息
 * @return 哈希过的消息
 */
const hashMessage = async (message) => {
  try {
    const resp = await web3.eth.accounts.hashMessage(message)
    return successResult(resp)
  } catch (err) {
    console.error(err)
    return failResult(err)
  }
}
/*
/**
* 连接metamask，获取账号
*/
const connectWallet = async () => {
  // 判断用户是否安装MetaMask钱包插件
  if (!window.ethereum) {
    // Toast('MetaMask is installed!')
    return failResult('Please install wallet plug-in.')
  }
  try {
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts'
    })
    // ethereum.enable() // 旧的API
    store.commit('SET_MY_ACCOUNT', accounts[0]) // 暂存account
    const ethChainId = await window.ethereum.request({ method: 'eth_chainId' })
    const ethChainNumberId = web3.utils.hexToNumber(ethChainId)
    if (ethChainNumberId !== config.ethChainNumberId) {
      window.ethereum
        .request({
          method: 'wallet_addEthereumChain',
          params: [config.chainConfig]
        })
        .then(() => {
          location.reload()
        })
    }
    return successResult(accounts[0])
  } catch (reason) {
    // if (!reason.code) {
    //   location.reload()
    //   return
    // }
    // reason.code => 4001 用户拒绝
    const message = reason.message || 'There was a problem signing you in'
    // Toast(reason.message)
    return failResult(message)
  }
}

/**
 * 获取当前token数量
 *@myAcount 当前账户地址
 */
const getBalance = async (myAcount = currentAccount) => {
  myAcount = store.state.myAcount
  try {
    var balance = await web3.eth.getBalance(myAcount)
    // balance = balance.toNumber() / Math.pow(10, 18)
    balance = web3.utils.fromWei(balance, 'ether')
    return successResult(balance)
  } catch (error) {
    return failResult(error)
  }
}
/**
 * 转账
 * @fromAddress 当前地址
 * @toAddress 目的地址
 * @value 数额
 */
const transfer = async (toAddress, value) => {
  value = web3.utils.toWei(value.toString(), 'ether')
  const currentAccount = store.state.myAcount
  try {
    const resp = await web3.eth.sendTransaction({
      from: currentAccount,
      to: toAddress,
      value: value,
      data: '0x63'
    })
    console.log(resp)
    return successResult(resp)
  } catch (err) {
    console.log(err)
    return failResult(err)
  }
}

/**
 * 查询某个账号的代币余额
 * @param {contractAddress} 币种合约
 * @param {currentAccount} 账户地址
 * @returns 代币数量
 */

const balanceOf = async (tokenAddress, account) => {
  const currentAccount = store.state.myAcount
  const myContract = await new web3.eth.Contract(tokenAbi, tokenAddress)
  try {
    // const resp = await myContract.methods.name().call({ from: contractObj.address })
    var balance = await myContract.methods
      .balanceOf(account || currentAccount)
      .call({})
    balance = web3.utils.fromWei(balance, 'ether')
    return successResult(balance)
  } catch (error) {
    return failResult(error)
  }
}

const fromWei = (amount) => {
  return web3.utils.fromWei(amount.toString(), 'ether')
}

const toWei = (amount) => {
  return web3.utils.toWei(amount.toString(), 'ether')
}
const gasPrice = web3.utils.toWei('1', 'gwei')
const gasPriceObj = {
  gasPrice: gasPrice
}
const web3Obj = {
  web3,
  failResult,
  successResult,
  getBlockNumber,
  ethereum,
  getBlock,
  getChainId,
  connectWallet,
  getBalance,
  transfer,
  balanceOf,
  fromWei,
  toWei,
  signData,
  hashMessage,
  gasPrice,
  gasPriceObj
}

export default web3Obj
