const ZH = {
  stake: '質押',
  choseStake: '選擇鎖倉策略',
  extract: '提出',
  mebTotalStake: 'LP質押總數量',
  currentLock: '活期鎖倉',
  currentMeb: '活期MEB',
  extractLp: '可提出的MIC-USDT LP',
  regularLock: '定期鎖倉',
  lockTime: '鎖倉時間',
  income: 'OAS收益',
  unlockTime: '解鎖時間',
  lockQuantity: '鎖倉數量',
  stakeNum: '質押數量',
  stakeValue: '質押',
  stakeRecord: '質押記錄',
  lockDay: '鎖倉天數',
  lock: '鎖倉',
  max: '最大',
  day: '天',
  h: '小时',
  min: '分钟',
  lack: '餘額不足',
  comfirm: '確定',
  cancle: '取消',
  supplyLP: '供應LP',
  extracted: '已提出',
  none: '暫無數據',
  current: '活期',
  notice: '*填寫邀請人後才可開始質押',
  noStart: '暫未開始',
  add: '從Pancake獲取LP',
  remove: '從Pancake取回LP',
  nowValue: '當前'
}
export default ZH
