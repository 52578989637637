export default {
  changeLang (ctx, lang) {
    ctx.commit('SET_LANG', lang)
  },
  changeAccount (ctx, myAcount) {
    ctx.commit('SET_MY_ACCOUNT', myAcount)
  },
  changeLoading (ctx, isLoading) {
    ctx.commit('SET_LOADING', isLoading)
  },
  changeSignData (ctx, signData) {
    ctx.commit('SET_SIGN', signData)
  }
}
