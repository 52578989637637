<template>
   <div id="app">
    <div class="home-top">
      <img src='@/assets/home/menu.png'  class="menu" @click='handleclose'/>
      <div class="wallet-box">
        <img src='@/assets/home/yuyan.png' class="language" @click='changeLanguage'/>
        <ConnectWallet />
      </div>
    </div>
      <router-view class="router-view" @changeLoading="changeLoading"></router-view>
      <SideBar @close="sideClose" v-if="sideShow" :showDropdown='dropdown'/>
      <Loading v-if="isLoading"></Loading>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ConnectWallet from '@/components/common/ConnectWallet.vue'
import SideBar from '@/components/SideBar.vue'
import Loading from './components/LoadingPop.vue'
export default {
  components: { Loading, ConnectWallet, SideBar },
  data () {
    return {
      pcClass: '', // pc-style
      loginPopShow: false, // 登录
      routerViewShow: true, // 组件显示
      loadinMessage: '', // 加载提示信息
      tronWeb: null,
      walletAddress: null,
      sideShow: false,
      dropdown: 0
    }
  },
  computed: {
    ...mapState(['mbLang', 'myAcount', 'isLoading', 'lang']),
    mobileFlag () {
      return this.isMobile()
    }
  },
  methods: {
    ...mapActions(['changeAccount', 'changeLoading', 'changeLang']),
    init () {
      this.listenWallet()
      this.connectWallet()
    },
    // 钱包监听
    listenWallet () {
      // 账号切换
      this.$web3.ethereum.on('accountsChanged', (accounts) => {
        console.log('accountsChanged')
        this.$router.go(0)
        this.changeAccount(accounts[0])
      })
      // 断开链接
      this.$web3.ethereum.on('disconnect', res => {
        console.log('disconnect')
        this.changeAccount()
        // this.toLogin()
      })
    },
    // 判断当前终端
    isMobile () {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
    // 连接钱包
    async connectWallet () {
      const resp = await this.$web3.connectWallet()
      if (resp.suceess) {
        console.log(resp)
      }
    },
    sideClose (index) {
      this.dropdown = index
      this.sideShow = !this.sideShow
    },
    changeLanguage () {
      const lang = this.lang === 'ZH' ? 'EN' : 'ZH'
      this.changeLang(lang)
      this.$emit('changeLange', lang)
    },
    handleclose () {
      this.sideShow = !this.sideShow
    }
  },
  mounted () {
    this.init()
    // 动态设置icon
    // var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
    // link.type = 'image/x-icon'
    // link.rel = 'shortcut icon'
    // link.href = 'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BB12cfTd.img' // icon图标
    // document.getElementsByTagName('head')[0].appendChild(link)
  }
}
</script>

<style lang="scss">
 html {
  box-sizing: border-box;
}
*,*::before,*::after {
  box-sizing: inherit;
}
body {
  // font-variant-east-asian: traditional;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}
::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  height: 0 !important;
  color: transparent;
}
#app {
  background-color: #18191A;
  background-size: 100% auto;
  background-repeat: repeat-y;
  min-height: 100vh;
  max-width: 450px;
  margin: auto;
}
.padding-layout {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px 10px 15px;
}
.loading-text {
  text-align: center;
  color: #66eab9;
  font-size: 14px;
  margin-top: 10px;
}
.van-button {
  border: none !important;
}
.home-top{
    padding: 10px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    height: 50px;
    background: #18191A;
    z-index: 2100;
    .wallet-box{
      display: flex;
      align-items: center;
      .language{
        margin-right: 20px;
      }
    }
  }
</style>
