<template>
<div class="loading-box">
    <VanPopup
    :overlay="false"
    :close-on-click-overlay="false"
    v-model="show"
    round
    class="round-box"
    style="background: unset;"
  >
    <div class="loadingWrap">
       <VanLoading color="#FFFFFF" vertical/>
    </div>
     <slot></slot>
  </VanPopup>
</div>
</template>

<script>
export default {
  name: 'loading',
  data () {
    return {
      show: true,
      timer: null,
      toastLoading: null,
      second: 15
    }
  },
  computed: {
  },
  methods: {
    init () {
      // this.setLoading()
      this.setTimer()
    },
    setTimer () {
      this.timer = setInterval(() => {
        this.second = this.second - 1
        if (!this.second) {
          clearInterval(this.timer)
          this.toastLoading = null
        }
      }, 1000)
    }
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.timer = null
  }
}
</script>

<style lang='scss' scoped>
.loading-box {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 22222;
}
.background {
  background: rgba(255, 255, 255, 0.3);
}
.loadingWrap {
  .login-img {
    width: 50px;
    height: auto;
    animation: rotate 1500ms linear infinite;
  }
  >>> .van-popup {
    background-color: rgba(255, 255, 255, 0.2);
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
.round-box {
  padding: 20px;
  box-sizing: border-box;
}
</style>
