const ZH = [{
  id: 0,
  text: '首頁',
  url: '/'
},
{
  id: 6,
  text: '元起通證競拍系統',
  url: 'https://v4.metacrowdfund.finance/',
  // url: 'https://test-v3.metacrowdfund.finance/',
  urlType: 1
},
{
  id: 1,
  text: '貢獻通證',
  url: '/contribution'
},
{
  id: 2,
  text: '社区生态',
  url: '',
  child: [
    {
      id: 1,
      text: '加密流',
      url: ''
    }
    // {
    //   id: 2,
    //   text: '元一眾籌(公測版)',
    //   url: 'https://metacrowdfund.finance/',
    //   urlType: 1
    // },
    //  {
    //   id: 3,
    //   text: '元一眾籌 V2.0(通道一 加速)',
    //   url: 'https://www.metacrowdfunds.finance/',
    //   urlType: 1
    // }, {
    //   id: 4,
    //   text: '元一眾籌 V2.0(通道一)',
    //   url: 'https://v2.metacrowdfund.finance/',
    //   urlType: 1
    // },
    // {
    //   id: 5,
    //   text: '元起通證競拍系統(USDT)',
    //   url: 'https://v3.metacrowdfund.finance/',
    //   urlType: 1
    // }
  ]
},
{
  id: 3,
  text: '算灋穩定幣',
  url: 'https://musd.finance/#/musd',
  urlType: 1
},
{
  id: 4,
  text: '質押借貸',
  url: 'https://musd.finance/#/mortage',
  urlType: 1
},
{
  id: 8,
  text: 'FlashMall',
  url: 'https://www.flashmall.app',
  urlType: 1
},
{
  id: 5,
  text: 'Web3.0人才庫元點',
  url: '/dao'
},
{
  id: 7,
  text: '人才庫元點空投',
  url: '/maiAirdrop'
}, {
  id: 9,
  text: 'OTC交易',
  url: '/otcTransaction'
}, {
  id: 10,
  text: 'OTC交易(旧)',
  url: '/otcTransactionV1'
}]
export default ZH
