const EN = {
  through: 'Through',
  donationPledge: ' donation/pledge ',
  available: ' available ',
  verticalLift: ' vertical lift ',
  levelQualification: ' level qualification',
  getInvolvedNow: 'Get involved now',
  donation: 'Donation ',
  or: 'or',
  pledge: 'Pledge ',
  tip: '*The pledge time needs to be 1 year',
  confirm: 'Confirm donation',
  tips: 'Through the donation of 15W MEB, we will make a crowdfunding V4',
  authorization: 'Authorization'
}
export default EN
