const EN = {
  ExistingAirdrop: 'Existing matrix point airdrop',
  TotalNumberOfOpen: 'Total number of open matrix points',
  Receiveairdrop: 'Receive airdrop',
  Estimated: 'To be estimated',
  Addpoint: 'Add matrix point airdrop',
  Newpoints: 'New matrix points',
  rule: 'Rule Description',
  ruleContent1: '<p>1. Only the total number of open matrix points with the maximum number of proposed LPs greater than or equal to 100 can be air dropped</p>' +
  '<p>2. The snapshot time of matrix points is 2022.10.29 21:00</p>',
  ruleContent2: '<p>1. You must first start the air drop of new matrix points to participate in the subsequent air drop of new elements</p>' +
  '<p>2. Every new matrix point bit added by the user can receive an air drop reward</p>',
  ruleContent3: '<p>Users with new lighting after 0:00 on March 11, 2023 can get MAI airdrop here.</p>',
  ActivateAirdrop: 'Activate airdrop',
  ReceiveAirdrop: 'Receive airdrop',
  Notopened: 'Not opened',
  maiPool: 'MAI distribution pool',
  estimatedToday: 'Estimated today',
  curLightReward: 'Current reward',
  once: 'once'
}
export default EN
