import i18n from '../main.js'
import config from '../config/app.config'

const projectName = config.projectName

export default {
  SET_LANG (state, lang) {
    localStorage.setItem(`${projectName}Lang`, lang)
    // console.log(i18n.t('home.title'))
    if (i18n) {
      i18n.locale = lang // i18n语言切换
    }
    state.lang = lang
  },
  SET_MY_ACCOUNT (state, myAcount) {
    state.myAcount = myAcount
    if (myAcount) {
      localStorage.setItem(`${projectName}Acount`, myAcount)
    } else {
      localStorage.removeItem(`${projectName}Acount`)
    }
  },
  SET_LOADING (state, isLoading) {
    state.isLoading = isLoading
  },
  SET_SIGN (state, signData) {
    if (signData) {
      localStorage.setItem(`${projectName}Sign`, JSON.stringify(signData))
    } else {
      localStorage.removeItem(`${projectName}Sign`)
    }
    state.signData = signData
  }
}
