const ZH = {
  amount: '捐贈額度',
  purpose: '捐贈用途',
  purposeDetails: '點石成金護盤資金',
  number: '捐贈數量',
  numberTips: '單個地址限一份',
  givingRights: '捐贈成效',
  equityContent: '享受總量5%分配',
  donations: '捐贈情況',
  alreadyExisting: '已有',
  situationContent: '人成功捐贈',
  surplus: '剩余',
  seat: '席位',
  numberOfDonations: '已捐贈數量',
  address: '捐贈地址',
  copy: '複製'
}
export default ZH
