const ZH = {
  contributionToken: 'Matrix 貢獻通證',
  slogan1: '為DeFi的發展生態',
  slogan2: '提供智慧合約',
  redemptionPrice: '贖回價格',
  totalIssuance: '發行總量',
  reserve: '準備金儲備',
  addresses: '當前持有地址數',
  contributionWithdrawal: '選取貢獻通證',
  yuanqiCrowdfunding: '元起眾籌',
  quantity: '數量',
  source: '來源',
  time: '時間',
  metaCrowd: '眾籌'
}
export default ZH
