const EN = {
  myLink: 'My invitation link',
  copy: 'copy',
  inviteFriends: 'Friends',
  effectiveFriends: 'Effective friends',
  contributionValue: 'Contribution value',
  identityLevel: 'Identity',
  inviteNumber: 'Invite',
  information: 'Direct push user information',
  userList: 'Direct push user list',
  userAddress: 'User address'
}
export default EN
