export default {
  pancakeRouter: '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3', // pancake router 合约地址
  factoryAddress: '0xB7926C0430Afb07AA7DEfDE6DA862aE0Bde767bc', // pancake factory
  // lightingAddress: '0x8E803097139875C66e905D852A7208d316a2ef02', // 点灯合约地址
  lightingAddress: '0x8347A810ff4d856A1571A5Ecc4672C0d0A52fA6c', // 点灯合约地址
  donationAddress: '0x810C2dd185dFd08b8d5656175f4f335a0ea61C78', // 捐赠地址
  crowdfunding: '0xebc15896594Cd15Df2020A382499Bb1bF065f2B0', // 众筹地址 metacrowd
  upgradeAddress: '0x7f4F982b42092D065d876F0082c2653C731546D6', // 捐赠直升V4地址
  maiExchangeAddress: '0x428c73703d90F850b913174771eFc413ABA90172', // mai赎回
  maiDistribute: '', // mai分发合约 测试链暂无
  lpBusiness: '', // 业务合约 测试链暂无
  maiAirDrop: '', // mai空投合约
  donateProcessor: '0xa81cC51642fb5e0D0b234560A08cc59810EDDB1f', // mcoinDonate
  claimAirDrop1: '0xfb2E1eB3CD49414f0c55d66388d7b656Ad77382e', // 矩阵空投领取
  otc: '', // otc交易
  otcV1: '0x0858f11B8Be3387D7fD1F3056A718e97Cc9E6d02', // otc交易
  otcAgent: '0xeD60b3103AB941980685aB2241DceCbDcBE18c14' // otc代理合约
}
