export default {
  USDT: '0x55d398326f99059fF775485246999027B3197955',
  MIC: '0x71Fc2c893e41EaBDF9C4Afda3b2Cdb46B93Cd8Aa',
  OAS: '0x80B4Bc8B96B29d9FbF7d8E04e66F5bd96EFbb167',
  MUSD: '0x22a2C54b15287472F4aDBe7587226E3c998CdD96',
  MCOIN: '0x826923122A8521Be36358Bdc53d3B4362B6f46E5',
  MEB: '0x7268B479eb7CE8D1B37Ef1FFc3b82d7383A1162d',
  MUSDMEB: '0xCCf2410dB619c759F901D568Bc993d4f4231F560',
  MAI: '0x35803e77c3163FEd8A942536C1c8e0d5bF90f906'
}
