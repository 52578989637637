const EN = {
  amount: 'Donation amount',
  purpose: 'Donation purposes',
  purposeDetails: 'Turning stone into gold to protect the market',
  number: 'Donation number',
  numberTips: 'A single address is limited to one copy',
  givingRights: 'Donated equity',
  equityContent: 'Enjoy a 5% bonus',
  donations: 'Donation',
  alreadyExisting: 'Already existing ',
  situationContent: ' people successful donation',
  surplus: 'surplus ',
  seat: ' seat',
  numberOfDonations: 'Donated quantity',
  address: 'Donation address',
  copy: 'copy'
}
export default EN
