const EN = {
  stake: 'Stake',
  choseStake: 'Select lock strategy',
  currentWeight: 'current exchange rate:',
  stakeWeight: 'Lock-up exchange ratio:',
  currentApy: 'Current Apy:',
  stakeApy: 'Lockdown Apy:',
  getMDAO: 'Get xMEB:',
  income: 'OAS Income',
  extract: 'Extract',
  stakeRecord: 'Stake record',
  stakeNum: 'Pledge quantity',
  stakeValue: 'stake value',
  mebTotalStake: 'Total LP pledges',
  max: 'Max',
  currentLock: 'Current lock',
  extractLp: 'Extractable MIC-USDT LP',
  regularLock: 'Regular lock-up',
  lockTime: 'Lock-up',
  lock: 'Lock',
  unlockTime: 'Unlock Time',
  lockQuantity: 'Lock Quantity',
  lockDay: 'Lockup date',
  day: 'days',
  h: 'hours',
  min: 'mins',
  lack: 'Lack of balance',
  comfirm: 'Comfirm',
  cancle: 'Cancle',
  supplyLP: 'Supply LP',
  extracted: 'Extracted',
  none: 'No data',
  current: 'Current',
  notice: '*You can start staking after filling in the inviter',
  noStart: 'Not started',
  add: 'Get LP from Pancake',
  remove: 'Retrieve LP from Pancake',
  nowValue: 'current value'
}
export default EN
