const EN = {
  Develop: 'Develop business for the community of defi',
  Provide: 'Provide smart contracts',
  based: 'Smart contract based',
  platform: 'Decentralized network platform',
  defi: 'Connect community and defi',
  let: 'let',
  tui: 'Defi promotion',
  createAccount: 'Create Account',
  bindRecommender: 'Bind recommender',
  Simpler: 'Simpler',
  join: 'join',
  easy: 'Everything is easy and simple',
  points: 'Cost of selected matrix points',
  maximum: 'Expected maximum return',
  point: 'Open matrix point',
  funds: 'Amount of launch pool funds',
  createTime: 'createTime',
  donation: 'Community donation',
  incentives: 'Marketing incentives',
  invitation: 'Ranking List',
  matrixs: 'Number of matrix points',
  pointStone: 'Turn stone into gold',
  pointStoneTips: 'It takes money to turn stone into gold',
  registerProject: 'Register your DeFi project',
  intoGold: 'Turn stone into gold',
  placeholderNumber: 'Please enter the matrix point to be opened',
  balance: 'Balance',
  get: 'get',
  pay: 'pay',
  estimatedCost: 'Estimated Cost',
  estimatedTotalRevenue: 'Estimated Total Revenue',
  reviewed: 'Submitted but not reviewed',
  inviteFriends: 'Invite Friends',
  supply: 'Inflection point supply',
  donateDividends: 'Donate Dividends',
  rewardDetails: 'Reward Details',
  seePointAward: 'Cumulative surprise point',
  dividend: 'Dividend',
  yuanTotal: 'Maximum withdrawable LP token',
  goldStone: 'Matrix point number',
  extinguished: 'Total number of closed matrix points',
  activation: 'Total number of open matrix points',
  cumulativeWithdrawal: 'Cumulative Withdrawal',
  bonusBalance: 'Remaining Total Points LP',
  withdraw: 'Extract',
  withdrawTips: 'Enter quantity',
  leaderboard: 'Leaderboard',
  contributionTotal: 'Contribution ranking total reward',
  giveOutRewards: 'Give Out Rewards',
  contribution: 'Contribution',
  expectedReward: 'Expected reward',
  accumulatedRewards: 'Accumulated rewards',
  partner: 'Partner',
  numbering: 'Numbering',
  numberOfPeople: 'address',
  contractAddress: 'Contract Address',
  yuanNumber: 'Number Of Yuan',
  amountOfFunds: 'Amount of Funds',
  creationTime: 'creation time',
  withdrawMaxTips: 'Maximum available ',
  addressTips: 'Please enter the address of the recommender',
  confirm: 'Sure',
  supplyLP: 'Supply',
  explain: 'Rule description',
  explainFirst: 'Each Yuan activation needs to be paid',
  explainSecond: "If the account has 900 activated yuan, you can activate yuan indefinitely. If it is less than 900 activated yuan, you can't activate it",
  explainThree: 'The activation element cannot be activated until the account is successfully created',
  matrix: 'Open your defi project community matrix',
  historicalRecords: 'Historical records',
  time: 'half past ten,14th May 2022',
  invitations: 'There are not enough invitations',
  shuoming: 'Identity statement',
  tipone: 'After the account is created successfully, it defaults to v0',
  tiptwo: 'Invite 6 v0s, users can upgrade to V1, and get 10 layer spot rewards',
  tipthree: 'Invite 4 v1s, users can upgrade to V2, and get 20 layer spot rewards',
  tipFour: 'Invite 2 v2s, users can upgrade to V3, and get 30 layer spot rewards，and 10 metabill can be unlocked every day',
  upgrade: 'upgrade',
  tipDialog: 'When creating an account, you need to activate one ',
  fail: 'Error evaluating gas, please try again',
  MetaCrowdfunding: 'Matrix Token Auction System',
  MetaCrowdfunding1: 'MetaCrowd(Beta)',
  MetaCrowdfunding2: 'MetaCrowd V2.0(channel 1 acceleration)',
  MetaCrowdfunding3: 'MetaCrowd V2.0(channel 1)',
  MetaBillPool: 'Web3.0 talent pool Metabill',
  workList: 'My work order',
  createTime1: 'Submit time',
  updateTime: 'Processing time',
  status: 'Status',
  remark: 'Remark',
  noStatus: 'Not processed',
  haveStatus: 'processed',
  noData: 'No Data',
  waitTips: 'If the query takes a long time, please try again after ten minutes',
  notNeed: 'No need to submit',
  youNeedPay: 'Need to pay ',
  Get: 'One click to get ',
  joinmeta: 'Join the talent matrix of meta point',
  getmulp: 'One click to get LP',
  pleaseInputLpAmount: 'Please enter the quantity of LP',
  getlpTips: 'Manually redeem MEB-USDT LP from Pancakeswap',
  maiAirdrop: 'Talent pool point airdrop',
  maiOtc: 'MAI OTC transaction'
}
export default EN
