const ZH = {
  through: '通過',
  donationPledge: '捐贈/質押',
  available: '可獲得',
  verticalLift: '直升',
  levelQualification: '級別資格',
  getInvolvedNow: '立即參與',
  donation: '捐贈',
  or: '或',
  pledge: '質押',
  tip: '*質押時間需要選擇1年',
  confirm: '確認捐贈',
  tips: '通過捐贈15W MEB直升元一眾籌V4',
  authorization: '授權'
}
export default ZH
