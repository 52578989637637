import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store/index'
import common from './common'
import requestMsg from './requestMsg'
import navigationBar from './navigationBar'
import tabBar from './tabBar'
import micStake from './micStake'
import donate from './donnate'
import community from './community'
import invite from './invite'
import donateDividends from './donateDividends'
import home from './home'
import sideBar from './sideBar'
import upgrade from './upgrade'
import contribution from './contribution'
import { ZH as ecologyZh, EN as ecologyEn } from './ecology'
import maiAirdrop from './maiAirdrop'
import otc from './otc.json'

Vue.use(VueI18n)
var lang = store.state.lang
if (!lang) {
  store.commit('SET_LANG', 'EN')
  lang = 'EN'
}

const EN = {
  requestMsg: requestMsg.EN,
  common: common.EN,
  navigationBar: navigationBar.EN,
  tabBar: tabBar.EN,
  micStake: micStake.EN,
  community: community.EN,
  invite: invite.EN,
  donateDividends: donateDividends.EN,
  donate: donate.EN,
  home: home.EN,
  upgrade: upgrade.EN,
  ecology: ecologyEn,
  contribution: contribution.EN,
  sideBar: sideBar.EN,
  maiAirdrop: maiAirdrop.EN,
  otc: otc.EN
}

const ZH = {
  requestMsg: requestMsg.ZH,
  common: common.ZH,
  navigationBar: navigationBar.ZH,
  tabBar: tabBar.ZH,
  micStake: micStake.ZH,
  community: community.ZH,
  invite: invite.ZH,
  donateDividends: donateDividends.ZH,
  donate: donate.ZH,
  home: home.ZH,
  upgrade: upgrade.ZH,
  ecology: ecologyZh,
  contribution: contribution.ZH,
  sideBar: sideBar.ZH,
  maiAirdrop: maiAirdrop.ZH,
  otc: otc.ZH
}

const i18n = new VueI18n({
  locale: lang,
  silentTranslationWarn: true,
  messages: {
    EN: EN,
    ZH: ZH
  }
})
export default i18n
