<template>
    <div class="connect-address">
      <div v-if="!myAcount" @click="connectWallet" class="link-div">
        <img src="../../assets/home/link.png" class="link"/>
        <div> {{ lang === 'EN' ? 'Connect Wallet' : '連接錢包' }}</div>
      </div>
      <div v-else class="link-div">
        <img src="../../assets/home/link.png" class="link"/>
        <div ><span class="col-theme">{{$gbUtils.formatAddress(myAcount)}}</span></div>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: '',
  // props: ['myAcount'],
  data () {
    return {
    }
  },
  computed: {
    ...mapState(['myAcount', 'lang'])
  },
  methods: {
    connectWallet () {
      // 需要弹窗签名
      // this.$emit('connectWallet')
      // 不需要弹窗签名
      this.$web3.connectWallet()
    }
  },
  mounted () {

  }
}
</script>

<style scoped lang="scss">
.connect-address {
  color: #fff;
}
.link-div{
  display: flex;
  align-items: center;
  padding: 5px 12px;
  background: #6667FF;
  border-radius: 26px 26px 26px 26px;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
}
.link{
margin-right: 5px;
}
</style>
