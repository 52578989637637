const EN = {
  community: 'Community',
  myLink: 'My invitation link',
  copy: 'copy',
  inviter: 'My inviter',
  enterLink: "Fill in the inviter's address",
  teamInfo: 'Team Info',
  teamNum: 'Total number of team members',
  totalLp: 'Direct push total LP value',
  teamTotalLp: 'Total LP value of the team',
  inviteNotice:
    'Please confirm whether the address is correct, this operation can be revoked by the person who revokes it',
  confirm: 'Confirm',
  commingSoon: 'Comming Soon',
  numError: 'Quantity Exceeds',
  getLp: 'One-click access to LP',
  balance: 'Balance',
  getMusd: 'GET MUSD',
  inputTokenAmount: 'Enter the number of {token}',
  remove: 'Remove',
  removeLp: 'Remove LP',
  imputRemoveAmount: 'Enter the number of {token} to retrieve'
}
export default EN
