export const EN = {
  title: 'M-dao Global launcher The first EGG DAO in the world',
  subTitle: '-Co-sponsors and creation community hot recruitment-',
  des: 'Fair start | transparent coordination | safety management',
  eggDes:
    "Explorer Guild Game DAO (EGG DAO for short) is the world's first guild-based financial DAO jointly initiated by the crypto community and meta-universe Guild. It is committed to providing incubation soil, capital enabling and flow support for WEB3.0, METAVERSE and GAMEFI track quality targets. The goal of EGG DAO is to capitalize the DAO community organization through a fair, open and transparent launch mechanism, establish a GUILDFI (guild finance) interconnected ecosystem of game, community and NFT assets, and strive to maximize the interests of the community and players.",
  mDaoDes:
    'M-dao is an important step in the decentralization of MEB. Come and make suggestions for the future ecological development of MEB',
  more: 'open',
  less: 'close',
  Alliance: 'The Alliance Community donation is in hot recruitment',
  fair: 'Fair start ｜ transparent cooperation ｜ Security Governance',
  donation: 'Genesis community donation',
  yuan: 'Yuandian ｜ decentralized marketing matrix 2.0',
  total: 'Total donation amount',
  purpose: 'Donation purpose',
  mark: 'Marketing matrix static revenue launch contract stability guarantee fund',
  Number: 'Number of donations',
  Donation: 'Donation at each address is limited to {nunber} MEB / copy',
  lock: 'Lock cycle',
  permanent: 'permanent',
  equity: 'Donated equity',
  dividend:
    'Metabill ｜ marketing matrix system 5% LP token sustainable dividend',
  Current: 'Current donation',
  remaining: 'remains {value}',
  have: 'Already {value} ',
  quantity: 'Donated quantity',
  address: 'Donation address',
  now: 'Participate now',
  marketing: 'decentralized marketing matrix 2.0',
  Genesis: 'Genesis community donation',
  grant: 'Approve',
  individual: '{total} individual',
  fen: 'Donation points',
  propaganda: {
    title: 'Leaflet',
    des1: 'Chinese Community Alliance joins hands with betabill',
    des2: 'Jointly contribute to the development of m-dao community',
    des3: 'The Alliance Community donation is in hot recruitment',
    notice1: 'Fair',
    notice2: 'Transparent',
    notice3: 'Security',
    check: 'Expand view'
  },
  adv: {
    title1: 'About',
    title2: 'Announcement of OTC transaction launch',
    date: 'August 30, 2023',
    advText1: 'The new version of OTC trading will start trading at 0:00 Beijing time on September 1, 2023. At that time, the old version of OTC trading cannot be added or the transaction can be listed. The new version of OTC will be launched, and a new round of Yuandian MAI air drop activities will be launched.',
    newVersion: 'New Version'
  }
}

export const ZH = {
  title: 'M-DAO全球發射器全球首發EGG DAO',
  subTitle: '-聯合發起人及創世社區火熱招募中-',
  des: '公平啟動 | 透明合作 | 安全治理',
  eggDes:
    'Explorer Guild Game DAO，簡稱EGG DAO，是全球首個由加密社區和元宇宙公會共同發起的公會化金融DAO，致力於為WEB3. 0、METAVERSE及GAMEFI賽道優質標的提供孵化土壤、資本賦能及流量支持。 EGG DAO的目標是通過公平、公開、透明的發射機制完成DAO社區組織的資本化，建立遊戲、社區和NFT資產的GUILDFI（公會化金融）互聯生態系統，努力實現社區及玩家的利益最大化。',
  mDaoDes: 'M-DAO是MEB去中心化的重要一步，快來為MEB未來生態發展建言獻策',
  more: '展開',
  less: '收起',
  Alliance: '聯盟社區捐贈火熱招募中',
  fair: '公平啟動 | 透明合作 | 安全治理',
  donation: '創世社區捐贈',
  yuan: '元點 | 去中心化合作矩陣2.0',
  total: '總捐贈額度',
  purpose: '捐贈用途',
  individual: '{total} 個',
  mark: '保障矩陣法神合約穩定',
  Number: '捐贈數量',
  Donation: '每個地址捐贈限定{nunber}/份',
  lock: '鎖定週期',
  permanent: '永久',
  equity: '捐贈成效',
  dividend: '5%LP',
  Current: '當前捐贈',
  remaining: '剩餘{value}',
  have: '已有{value}',
  quantity: '已捐贈數量',
  address: '捐贈位址',
  now: '立即參與',
  marketing: '去中心化合作矩陣2.0',
  Genesis: '捐贈',
  grant: '授權',
  fen: '捐贈分點',
  propaganda: {
    title: '文宣頁',
    des1: '華語社區聯盟攜手BetaBill',
    des2: '共同助力M-DAO社區發展',
    des3: '聯盟社區捐贈火熱招募中',
    notice1: '公平啟動',
    notice2: '透明合作',
    notice3: '安全治理',
    check: '展開查看'
  },
  adv: {
    title1: '關於',
    title2: 'OTC交易上線的公告',
    date: '2023 年 8 月 30 日',
    advText1: '新版OTC交易將在北京时間2023.9.1 0:00開啟交易，届時舊版OTC交易無法新增以及成交掛單。 新版OTC上線，將開啟新一輪的元點MAI空投活動。',
    advText2: '貸款可借出的比例調整為抵押資產的80%，借貸到期後支付利息，提前還款算違約利息加倍。 365天，年化利率5.5%，300天內提前還款按11%年化利率計算利息，逾期罰息實时累加折合年化11%； 180天，年化利率10%，150天內提前還款或逾期按20%年化利率計算利息，逾期罰息實时累加折合年化20%； 90天，年化利率18%，75天內提前還款或逾期按36%年化利率計算利息，逾期罰息實时累加折合年化36%； 取消7天活期借貸，原活期借貸利率調整至25%。',
    newVersion: '新版'
  }
}
