const EN = {
  mebTitle: 'MEB Community Donation',
  micTitle: 'MIC Community Donation',
  rewardTitle: 'Donate LP Rewards',
  amount: 'Donation amount',
  zongji: 'Total',
  wan: 'Ten thousand',
  limit: 'Limit',
  fenshu: 'Number of donations',
  fen: 'parts',
  number: 'Donation number',
  perDonate: 'per donation',
  current: 'Current donation',
  have: 'Already',
  successDonate: 'people donate successfully,',
  remain: 'remaining',
  seat: 'seat',
  noStart: 'not open',
  donated: 'Donated',
  ended: 'Ended',
  auth: 'Supply',
  donate: 'Donate now',
  get: 'Receive award',
  time: 'Open hours',
  alreadyGet: 'Received'
}
export default EN
