import devpConfig from './devp.config'
import prodConfig from './prod.config'
import testConfig from './test.config'

let config
let addressConfig

switch (process.env.RELEASE_ENV) {
  case 'prod':
    config = prodConfig
    break
  case 'test':
    console.log(process.env.RELEASE_ENV)
    config = testConfig
    break
  default:
    console.log('process.env.RELEASE_ENV')
    config = devpConfig
    config = testConfig
    config = prodConfig
    // 开启跨域，调试正式服的时候需要打开
    // config.server = config.flashMallServe = '/mbApi'
}

const projectName = 'dapp'

export default {
  ...config,
  ...addressConfig,
  projectName
}
