const ZH = {
  mebTitle: 'MEB社區捐贈',
  micTitle: 'MIC社區捐贈',
  rewardTitle: '捐贈LP分點',
  amount: '捐贈額度',
  zongji: '總計',
  wan: '萬',
  limit: '限',
  fenshu: '捐贈份數',
  fen: '份',
  number: '捐贈數量',
  perDonate: '每份捐贈',
  current: '當前捐贈',
  have: '已有',
  successDonate: '人成功捐贈，',
  remain: '剩餘',
  seat: '席位',
  noStart: '未開始',
  donated: '已捐款',
  ended: '已結束',
  auth: '供應',
  donate: '立即捐贈',
  get: '領取',
  time: '開放時間',
  alreadyGet: '已領取'
}
export default ZH
