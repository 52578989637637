const EN = [{
  id: 0,
  text: 'Home page',
  url: '/'
},
{
  id: 6,
  text: 'Matrix Token Auction System',
  url: 'https://v4.metacrowdfund.finance/',
  urlType: 1
},
{
  id: 1,
  text: 'Contribution token',
  url: '/contribution'
},
{
  id: 2,
  text: 'Community ecology',
  url: '',
  child: [{
    id: 1,
    text: 'Encrypted stream',
    url: ''
  }
  // {
  //   id: 2,
  //   text: 'MetaCrowd(Beta)',
  //   url: 'https://metacrowdfund.finance/',
  //   urlType: 1
  // }, {
  //   id: 3,
  //   text: 'MetaCrowd V2.0(channel 1 acceleration)',
  //   url: 'https://www.metacrowdfunds.finance/',
  //   urlType: 1
  // }, {
  //   id: 4,
  //   text: 'MetaCrowd V2.0(channel 1)',
  //   url: 'https://v2.metacrowdfund.finance/',
  //   urlType: 1
  // },
  // {
  //   id: 5,
  //   text: 'Matrix Token Auction System(USDT)',
  //   url: 'https://v3.metacrowdfund.finance/',
  //   urlType: 1
  // }
  ]
},
{
  id: 3,
  text: 'Calculate stable currency',
  url: 'https://musd.finance/#/musd',
  urlType: 1
},
{
  id: 4,
  text: 'Pledge loan',
  url: 'https://musd.finance/#/mortage',
  urlType: 1
},
{
  id: 8,
  text: 'FlashMall',
  url: 'https://www.flashmall.app',
  urlType: 1
},
{
  id: 5,
  text: 'Web3.0 talent pool Metabill',
  url: '/dao'
},
{
  id: 7,
  text: 'Talent pool point airdrop',
  url: '/maiAirdrop'
}, {
  id: 9,
  text: 'OTC Transaction',
  url: '/otcTransaction'
}, {
  id: 10,
  text: 'OTC Transaction(old)',
  url: '/otcTransactionV1'
}]
export default EN
