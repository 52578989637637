const ZH = {
  myLink: '我的邀請鏈接',
  copy: '複製',
  inviteFriends: '好友',
  effectiveFriends: '有效好友',
  contributionValue: '貢獻值',
  identityLevel: '身份',
  inviteNumber: '邀請',
  information: '直推用戶信息',
  userList: '直推用戶列表',
  userAddress: '用戶地址'
}
export default ZH
