const EN = {
  contributionToken: 'Matrix Contribution token',
  slogan1: "For DeFi's development ecology",
  slogan2: 'Provide smart contracts',
  redemptionPrice: 'Redemption price',
  totalIssuance: 'Total issuance',
  reserve: 'Reserve',
  addresses: 'Current number of addresses',
  contributionWithdrawal: 'Withdrawal of contribution certificate',
  yuanqiCrowdfunding: 'Meta crowdfund',
  quantity: 'Quantity',
  source: 'Source',
  time: 'Time',
  metaCrowd: 'metaCrowd'
}
export default EN
